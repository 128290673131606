@import url(https://fonts.google.com/specimen/Poppins#standard-styles);

:root
{
    /* colors */
    --primary-color : #5ea430;

    /* footer */
    --footer-primary-bg-color : #1b6296;
    --footer-secondary-bg-color : #004273;

    /* fonts */
    --primary-font-family : "Poppins", sans-serif;
    --heading-font-size : 3vh;
    --paragraph-font-size : 2vh;
}

#bold
{
  font-weight: bold;
}

#error
{
  color: red;
}