/* =>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=> */
/* Desktop version Starts here */
/* =>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=> */

/* Home-page starts here */

.heading_container,
.home_email_container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.home-container {
  width: 100%;
  color: var(--primary-color);
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 10vh;
}

.heading_container {
  width: 50%;
  color: var(--primary-color);
  font-size: var(--heading-font-size);
  font-weight: 700;
  text-align: center;
  font-family: var(--primary-font-family);
  text-transform: uppercase;
}

.home_email_container {
  margin-top: 10px;
}

.home_email_container > img {
  height: 3vh;
  margin-right: 4px;
}

.home_email_container > .email{
  display: block;
  text-decoration: none;
  font-style: normal;
  font-size: 2.5vh;
  color: #666666;
}

.main_image_container {
  height: 70vh;
  margin-top: 5vh;
  width: 100%;
  background: #f2f2f2 0 0 no-repeat padding-box;
  /* box-shadow: 0 0 10px #00000029; */
  opacity: 1;
}

.main_image_container > img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

/* Home-page ends here */

/* --------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */

/* Service-page starts here */

.service_container,
.heading_container,
.service_box_container,
.box,
.icon,
.box_title,
.box_content {
  display: flex;
  align-items: center;
}

.service_container,
.box_content {
  justify-content: flex-start;
}

.service_container,
.box,
.heading_container {
  flex-direction: column;
}

.service_container {
  width: 100%;
  margin-bottom: 20px;
}

.heading_container {
  justify-content: center;
}

.service_title {
  color: var(--primary-color);
  font-size: var(--heading-font-size);
  font-weight: 700;
  text-align: center;
  font-family: var(--primary-font-family);
}

.service_content {
  color: #666666;
  font-size: var(--paragraph-font-size);
  font-weight: 400;
  margin-top: 10px;
  text-align: center;
  width: 65%;
  font-family: var(--primary-font-family);
}

.service_box_container {
  width: 90%;
  margin-top: 20px;
  justify-content: center;
  flex-wrap: wrap;
}

.box {
  width: 20%;
  min-height: 40vh;
  padding: 10px;
  margin: 20px;
  border-radius: 30px;
  background: linear-gradient(145deg, #e6e6e6, #ffffff);
  box-shadow:  20px 20px 60px #d9d9d9,
             -20px -20px 60px #ffffff;
  opacity: 1;
  justify-content: center;
  cursor: pointer;
}

.icon {
  height: 18vh;
  width: 95%;
  padding-left: 10px;
  justify-content: center;
  /* background-color: tomato; */
}

.icon > img {
  width: 90%;
  height: 100%;
}

.box_title {
  width: 95%;
  padding-left: 10px;
  color: #000000;
  font-size: 3vh;
  font-weight: bold;
  margin-top: 5px;
  text-align: center;
  justify-content: center;
}

.box_content {
  width: 95%;
  padding: 10px;
  color: #666666;
  font-size: var(--paragraph-font-size);
  font-weight: 400;
  margin-top: 5px;
  text-align: center;
}

/* Service-page ends here */

/* --------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */

/* contact-page starts here */

.contact_us_container,
.container_wrap {
  display: flex;
  align-items: center;
  justify-content: center;
}

.contact_us_subheading,
.contact_us_content,
.label {
  font-weight: 400;
  margin-top: 10px;
  font-family: var(--primary-font-family);
}

.contact_us_container {
  height: 100vh;
  width: 100%;
  /* margin-top: 10vh; */
}

.container_wrap {
  height: 95%;
  width: 80%;
}

.left_column {
  height: 100%;
  width: 50%;
}

.right_column {
  height: 100%;
  width: 50%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.contact_us_title {
  color: var(--primary-color);
  font-size: var(--heading-font-size);
  font-weight: bold;
  font-family: var(--primary-font-family);
}

.contact_us_subheading {
  color: #000000;
  font-size: 2vh;
}

.contact_us_content {
  color: #000000;
  font-size: 2vh;
}

.form_container_warp {
  min-width: 70%;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  padding: 20px;
  border-radius: 20px;
  background: linear-gradient(145deg, #e6e6e6, #ffffff);
  box-shadow:  20px 20px 60px #d9d9d9,
             -20px -20px 60px #ffffff;
}

.input_container {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin: 20px;
  border-radius: 50px;
}

form {
  width: 90%;
}

.form__field {
  height: 4vh;
  width: 90%;
  border: 1px solid #dedede;
  opacity: 1;
  padding: 10px;
  border-radius: 20px;
  background: linear-gradient(145deg, #e6e6e6, #ffffff);
  box-shadow:  10px 10px 20px #d9d9d9,
             -10px -10px 20px #ffffff;
}

.text_area {
  height: 7vh;
  width: 90%;
  padding: 10px;
  border: 1px solid #dedede;
  opacity: 1;
  border-radius: 30px;
  background: linear-gradient(145deg, #e6e6e6, #ffffff);
  box-shadow:  20px 20px 60px #d9d9d9,
             -20px -20px 60px #ffffff;
}

.label {
  color: #666666;
  font-size: var(--paragraph-font-size);
}

.button_position {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 10px;
  margin-bottom: 10px;
  margin-top: 10px;
}
.submit {
  font-size: var(--paragraph-font-size);
  font-weight: 500;
  color: white;
  background: #5ea430;
  border: 0;
  padding: 5px 10px 5px 10px;
  border-radius: 5px;
  font-family: var(--primary-font-family);
}


/* contact-page ends here */

/* --------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */

/* =>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=> */
/* Desktop version ends here */
/* =>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=> */

/* --------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */

/* style sheet for tablet and mobile on and below 768 pixels */

/* =>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=> */
/* Mobile version starts here */
/* =>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=> */

@media only screen and (max-device-width: 768px) {
  /* Home-page starts here */
  .heading_container {
    font-size: 3vh;
    width: 70%;
  }

  .main_image_container > img {
    object-fit: contain;
  }

  .main_image_container {
    height: auto;
  }
  /* Home-page ends here */

  /* --------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */

  /* Service-page starts here */
  .box {
    width: 70%;
  }

  .icon > img {
    width: 55%;
  }

  .service_content
  {
    width: 100%;
  }
  /* Service-page ends here */

  /* --------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */

  /* Contact-page starts here */
  .contact_us_container {
    height: 120vh;
    margin-bottom: 20vh;
  }

  .container_wrap {
    flex-direction: column;
    margin-top: 0;
  }
  .left_column {
    height: auto;
    width: 90%;
    text-align: center;
  }
  .right_column {
    height: 100%;
    width: 100%;
    margin-top: 40px;
  }
  .form_container_warp {
    min-width: 100%;
  }
  .form__field {
    height: 5vh;
    width: 100%;
  }
  .label {
    font-size: 12px;
  }
  .recapt
  {
    width: 4vw;
  }
  #lottie-arrow
  {
    display: none;
  }
  
  
  /* Contact-page ends here */
}

/* =>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=> */
/* Mobile version ends here */
/* =>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=> */


/* cookies popup */


body {
  font-family: var(--primary-font-family);
}

#simple-cookie-consent {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  resize: vertical;
  overflow: auto;
  background: rgba(0, 0, 0, 0);
}
#simple-cookie-consent .cookie-consent-container {
  position: absolute;
  top: 80%;
  left: 2%;
  margin: -100px auto 0;
  background: rgb(83, 83, 83);
  padding: 20px;
  max-width: 500px;
  color:rgb(255, 255, 255);
  border-radius:10px;
}
#simple-cookie-consent .cookie-consent-selection {
  text-align: right;
}
#simple-cookie-consent button {
  border: none;
  padding: 10px 20px;
  margin: 10px 0 0 10px;
  background: none;
  font-size: 1.1em;
}
#simple-cookie-consent button.cookie-consent-allow {
  background-color: var(--primary-color);
  color: #fff;
  font-weight:bold;
}
#simple-cookie-consent button.cookie-consent-allow:focus,
#simple-cookie-consent button.cookie-consent-allow:hover {
  background-color: #059862;
  cursor: pointer;
}
#simple-cookie-consent button.cookie-consent-deny {
  padding: 5px 0;
  font-size: 0.9em;
  opacity: 0.8;
}
#simple-cookie-consent button.cookie-consent-deny:focus,
button.cookie-consent-deny:hover {
  opacity: 1;
  cursor: pointer;
}
