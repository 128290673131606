.career_main_container {
  height: 60vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
}

.career_title_container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.career_title {
  color: var(--primary-color);
  font-size: var(--heading-font-size);
  font-weight: bold;
  font-family: var(--primary-font-family);
}

.career_title_container > img {
  width: 10vw;
  height: 10vh;
}

.career_content {
  color: #666666;
  font-size: var(--paragraph-font-size);
  font-weight: 400;
  margin-top: 10px;
  text-align: center;
  width: 30%;
  font-family: var(--primary-font-family);
}

.email_container {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.email_container > img {
  height: 2.5vh;
  margin-right: 4px;
}

.email_container > .email {
  font-size: var(--paragraph-font-size);
  color: #666666;
}

.career_box_container {
  width: 100%;
  margin-top: 40px;
  padding-top: 10px;
  display: flex;
  flex-direction: flex-start;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
}

.career_box {
  height: 10vh;
  width: 30%;
  margin: 10px;
  display: flex;
  flex-direction: flex-;
  align-items: flex-start;
  justify-content: center;
  background: ffffff 0 0 no-repeat padding-box;
  background: #ffffff 0 0 no-repeat padding-box;
  box-shadow: 0 0 5px #00000029;
  border: 1px solid #e8e8e8;
  border-radius: 5px;
  opacity: 1;
}

.LPOB {
  height: 100%;
  width: 60%;
  display: flex;
  padding-left: 10px;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
}

.career_box_title {
  color: #050505;
  font-size: 1.5vh;
  font-weight: bold;
  margin-top: 10px;
}

.career_box_subheading {
  color: #666666;
  font-size: 1.5vh;
  font-weight: bold;
  margin-top: 10px;
}

.RPOB {
  height: 100%;
  width: 40%;
  display: flex;
  padding-left: 10px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.RPOB > button {
  background: var(--unnamed-color-ffffff) 0 0 no-repeat padding-box;
  border: 1px solid var(--logo-color-1b6296);
  background: #ffffff 0 0 no-repeat padding-box;
  border: 1px solid #1b6296;
  border-radius: 5px;
  opacity: 1;
  font-size: var(--paragraph-font-size);
  color: #1b6296;
  cursor: pointer;
}

@media only screen and (max-device-width: 768px) {
  .career_main_container {
    height: 100vh;
  }
  .career_content {
    width: 90%;
  }
  .career_box_container {
    padding: 0;
  }
  .career_box {
    width: 90%;
  }
}
