section
{
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
}

.text-warp
{
    width: 60vw;
    height: fit-content;
    display: flex;
    flex-direction: column;
}

.TACHeading
{
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
}

.TACPara
{
    text-align: justify;
    margin-bottom: 10px;
}

@media only screen and (max-device-width: 768px) 
{
    .text-warp
    {
        margin-bottom: 10vh;
    }
}