@keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes slideIn {
  0% {
    transform: translateY(50%);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes main {
  0% {
    transform: scaleX(0);
  }
  100% {
    transform: scaleX(-1);
  }
}

/* sidebar Nav */
.menuContainer {
  position: fixed;
  transition: background 1s;
  will-change: background;
  z-index: 10;
  cursor: pointer;
  background-color: var(--primary-color);
  box-shadow: 0px 0px 10px #00000029;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  opacity: 1;
  color: white;
  padding-bottom: 10px;
  padding-top: 15px;
  padding-right: 30px;
  padding-left: 30px;
  margin-top: 23px;
  margin-left: -30px;
  font-size: 2vh;
  font-family: var(--primary-font-family);
  transform: rotate(-90deg);
}

.menuContainer:hover {
  background-color: #587759;
  color: white;
}

.menuContainer.close {
  background-color: #363636;
}

.main_menu_container {
  position: fixed;
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: 9;
  width: 100%;
  height: 0vh;
  background: #252525;
  transition: all 0.25s ease-in;
}

.menu_sub_container
{
  height: 100%;
  width: 100vw;
  overflow: scroll;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5vh;
}

.menu_sub_container::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.menu_sub_container {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.main_menu_container.show_menu {
  height: 100%;
  transition: all 0.5s ease-out;
}

.main_menu_container > .menu_sub_container > .left_column_container {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 80vw;
  height: 80%;
  margin-left: 5vh;
}

.left_column_container > .menu_container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-right: 50px;
}


.menu_footer_inner_container_heading {
  color: var(--primary-color);
  display: flex;
  align-items: center;
  font-size: 2.8vh;
  font-weight: 800;
  margin-bottom: 20px;
  font-family: var(--primary-font-family);
}

.menu_inner_container_content
{
  color: #727271;
  font-size: 2.2vh;
  font-weight: 500;
  font-family: var(--primary-font-family);
  margin-left: 30px;
}

.menu_content_text {
  margin-bottom: 10px;
  cursor: pointer;
}

.menu_container > .menu_item_container {
  opacity: 0;
  animation: appear 1s forwards;
  animation-delay: 0.5ms;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.menu_item_container > .menu_item {
  color: white;
  font-family: var(--primary-font-family);
  font-size: var(--heading-font-size);
  padding-bottom: 30px;
  cursor: pointer;
  z-index: 2;
  transition: color 0.2s ease-in-out;
  animation: slideIn 1s forwards;
  animation-delay: 1ms;
  flex-direction: column;
}

.menu_item:hover {
  color: var(--primary-color);
}

.HashLink {
  color: inherit;
  text-decoration: inherit;
}

.menu_item::after {
  content: "→";
  position: absolute;
  padding-left: 15px;
  transform: scale(1.5);
  opacity: 0;
  transition: 0.5s forwards;
}

.menu_item:hover .hoverbox {
  position: fixed;
  opacity: 0.2;
  float: left;
  background-color: grey;
  height: 30px;
  width: 100px;
  margin-top: -20px;
  margin-left: -150px;
  z-index: -1;
  transform-origin: 100% 50%;
  animation: main 0.5s forwards ease-in-out;
}

.menu_item:hover::after {
  opacity: 1;
}

.left_column_container > .social_container {
  display: flex;
  align-items: center;
  flex-direction: row;
  animation: appear 3s forwards;
}

.menu_image
{
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-right: 50px;
}

.menu_inner_container_content > .menu_image > img {
  height: 3vh;
  width: 3vw;
  display: block;
  margin-left: -1vw;
  margin-right: 2vw;
  transition: color 0.3s ease-in-out;
  animation: slideIn 2s forwards;
  cursor: pointer;
}

.main_menu_container > .right_column_container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 50%;
}

.right_column_container > .estimate_container {
  color: white;
  position: absolute;
  top: 0px;
  right: 0px;
  padding-top: 2%;
  padding-bottom: 1.5%;
  padding-left: 3%;
  padding-right: 5%;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  animation: appear 2s forwards;
  transition: 0.3s ease-in-out;
}

.estimate_container > .message_us_button {
  color: black;
  transition: background 1s ease-out;
  will-change: background;
  cursor: pointer;
  background-color: #dfdfdf;
  margin-top: 5%;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  transition: color 0.3s ease-in-out;
  animation: slideIn 2s forwards;
}

.right_column_container > .contact_container {
  position: absolute;
  bottom: 0px;
  right: 0px;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  width: 40%;
  padding-right: 2%;
  padding-bottom: 2%;
  animation: appear 2s forwards;
}

.contact_container > .contact_item_container {
  display: flex;
  align-items: center;
  flex-direction: row;
  padding-right: 3vw;
  padding-bottom: 1.5vh;
  transition: color 0.3s ease-in-out;
  animation: slideIn 2s forwards;
}

.contact_item_container > img {
  height: 1vh;
  width: 1vw;
  padding-right: 1vw;
}

.contact_item_container > .contact_content {
  font-family: var(--primary-font-family);
  font-size: 1vh;
  font-weight: normal;
  color: #929292;
}

.menu_footer_inner_container_heading1
  {
    color: var(--primary-color);
    display: flex;
    align-items: center;
    font-size: 1.8vh;
    font-weight: 800;
    margin-top: 30px;
    margin-bottom: 10px;
    font-family: var(--primary-font-family);
  }

@media only screen and (max-device-width: 768px) {
  .right_column_container > .estimate_container {
    display: none;
  }

  .main_menu_container > .menu_sub_container > .left_column_container
  {
    flex-direction: column;
    margin-top: 35vh;
  }

  .menu_footer_inner_container_heading1
  {
    color: var(--primary-color);
    display: flex;
    align-items: center;
    font-size: 1.8vh;
    font-weight: 800;
    margin-top: 30px;
    margin-bottom: 10px;
    font-family: var(--primary-font-family);
  }

  .menu_inner_container_content
  {
    margin-left: 0%;
    margin-bottom: 5vh;
  }
  
  .right_column_container > .contact_container {
    position: absolute;
    bottom: 10%;
    left: 15%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    width: 40%;
    padding-right: 2%;
    padding-bottom: 2%;
    animation: appear 2s forwards;
  }

  .left_column_container > .social_container {
    position: absolute;
    padding-left: 15%;
    padding-bottom: 5%;
    top: 70vh;
    left: 0px;
    display: flex;
    align-items: center;
    flex-direction: row;
    animation: appear 3s forwards;
  }
  
  .social_container > img {
    height: 4vh;
    width: 4vw;
    padding-left: 1vw;
    padding-right: 1vw;
    transition: color 0.3s ease-in-out;
    animation: slideIn 2s forwards;
  }

  .menu_inner_container_content > .menu_image > img
  {
    width: 5vw;
    margin-right: 6vw;
  }
}

@media only screen and (max-device-width: 420px) {
  .main_menu_container > .menu_sub_container > .left_column_container
  {
    flex-direction: column;
    margin-top: 50vh;
  }

  .menu_inner_container_content
  {
    margin-left: 0%;
    margin-bottom: 5vh;
  }

  .right_column_container > .contact_container {
    bottom: 8%;
  }
}

@media only screen and (max-device-width: 320px) {
  /* .main_menu_container > .menu_sub_container > .left_column_container
  {
    margin-top: 80vh;
  } */
}