.career_form_container
{
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    margin-bottom: 5vh;
}

.career_form_title
{
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--primary-color);
    font-size: 3vh;
    font-weight: bold;
    font-family: var(--primary-font-family);
    text-align: center;
    text-transform: uppercase;
}

.form_container_warp_career {
    min-width: 40%;
    background: #f3f3f3 0% 0% no-repeat padding-box;
    border-radius: 10px;
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 20px;
    border-radius: 20px;
    background: linear-gradient(145deg, #e6e6e6, #ffffff);
    box-shadow:  20px 20px 60px #d9d9d9,
             -20px -20px 60px #ffffff;
  }

  .recapt
  {
    width: 100%;
    height: 10vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .input_container {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    margin: 20px;
  }

  .input_radio_container
  {
    margin: 20px;
  }
  
  form {
    width: 90%;
  }

  input[type=radio] {
    border: 0px;
    height: 20px;
    width: 20px;
    margin: 2vh;
}
  
  .form__field {
    height: 4vh;
  width: 90%;
  border: 1px solid #dedede;
  opacity: 1;
  padding: 10px;
  border-radius: 20px;
  background: linear-gradient(145deg, #e6e6e6, #ffffff);
  box-shadow:  20px 20px 60px #d9d9d9,
             -20px -20px 60px #ffffff;
  }

  
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }

  /* switch */
  
  .text_area {
    height: 7vh;
    width: 90%;
    padding: 10px;
    border: 1px solid #dedede;
    opacity: 1;
    border-radius: 30px;
    background: linear-gradient(145deg, #e6e6e6, #ffffff);
    box-shadow:  20px 20px 60px #d9d9d9,
               -20px -20px 60px #ffffff;
  }
  
  .label {
    color: #666666;
    font-size: var(--paragraph-font-size);
  }

  .radio_label {
    color: var(--primary-color);
    font-size: 2.7vh;
    font-weight: bold;
    font-family: var(--primary-font-family);
  }

  input{
    color: #666666;
    font-size: var(--paragraph-font-size);
    font-weight: 200;
    font-family: var(--primary-font-family);
  }
  textarea
  {
    color: #666666;
    font-size: var(--paragraph-font-size);
    font-weight: 200;
    font-family: var(--primary-font-family);
  }
  
  .button_position {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    margin-bottom: 10px;
  }
  .submit {
    font-size: var(--paragraph-font-size);
    font-weight: 500;
    color: white;
    background: var(--primary-color);
    border: 0;
    padding: 5px 10px 5px 10px;
    border-radius: 5px;
    font-family: var(--primary-font-family);
  }


  @media only screen and (max-device-width: 768px) {

    .career_form_body
    {
        margin-bottom: 40vh;
    }
    .form_container_warp_career
    {
        min-width: 80%;
        
    }

  }