.body {
  width: 100vw;
  margin-bottom: 10vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.contactpageTitle {
  width: 100%;
  text-align: center;
  font-size: var(--heading-font-size);
  font-weight: bold;
  color: var(--primary-color);
  font-family: var(--primary-font-family);
}

.contactpage_left {
  height: 100%;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contactpage_right {
  height: 100%;
  width: 50%;
  /* background: rgb(255, 0, 0); */
  display: flex;
  flex-direction: column;
}

.contactpagerightDown iframe {
  width: 80%;
  height: 40vh;
}

.contactpagerightUp {
  height: 50%;
  /* background: rgb(8, 255, 140); */
  padding: 30px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.contactadressContainer {
  display: flex;
}

.contactpageaddressWarp {
  margin: 20px;
  padding: 10px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(145deg, #e6e6e6, #ffffff);
  box-shadow: 20px 20px 60px #d9d9d9, -20px -20px 60px #ffffff;
}

.contactpagerightDown {
  height: 50%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 30px;
  /* background: rgb(8, 24, 255); */
}

/* form style */

.form_container_warp {
  width: 50%;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  padding: 20px;
  border-radius: 20px;
  background: linear-gradient(145deg, #e6e6e6, #ffffff);
  box-shadow: 20px 20px 60px #d9d9d9, -20px -20px 60px #ffffff;
}

.input_container {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 20px;
  border-radius: 50px;
}

form {
  width: 90%;
}

.form__field {
  height: 4vh;
  width: 90%;
  border: 1px solid #dedede;
  opacity: 1;
  padding: 10px;
  border-radius: 20px;
  background: linear-gradient(145deg, #e6e6e6, #ffffff);
  box-shadow:  20px 20px 60px #d9d9d9,
             -20px -20px 60px #ffffff;
}

.text_area {
  height: 7vh;
  width: 90%;
  padding: 10px;
  border: 1px solid #dedede;
  opacity: 1;
  border-radius: 30px;
  background: linear-gradient(145deg, #e6e6e6, #ffffff);
  box-shadow:  20px 20px 60px #d9d9d9,
             -20px -20px 60px #ffffff;
}

.label {
  color: #666666;
  font-size: var(--paragraph-font-size);
}

.label_ {
  color: #000000;
  font-size: var(--paragraph-font-size);
  font-family: var(--primary-font-family);
  text-transform: uppercase;
  font-weight: bold;
}

.button_position {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  margin-bottom: 10px;
}
.submit {
  font-size: var(--paragraph-font-size);
  font-weight: 500;
  color: white;
  background: var(--primary-color);
  border: 0;
  padding: 5px 10px 5px 10px;
  border-radius: 5px;
  font-family: var(--primary-font-family);
}

.contact_component {
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
}

.contact_component > img {
  height: 30px;
  margin-right: 15px;
}

.contact_component > .email {
  font-size: var(--paragraph-font-size);
  color: rgb(92, 92, 92);
  font-weight: 500;
  font-family: var(--primary-font-family);
}

.contact-container-position
{
  text-align: center;
  margin-bottom: 3vh;

}

@media only screen and (max-device-width: 768px) {
  .career_form_body {
    margin-bottom: 40vh;
  }

  .contactpageTitle {
    margin-bottom: 10px;
  }

  .body {
    flex-direction: column;
  }

  .contactpage_left {
    width: 90%;
  }

  .contactpage_right {
    width: 100%;
  }
  .contactadressContainer {
    flex-direction: column;
  }
  .contactpagerightDown iframe {
    width: 80%;
    height: 35vh;
  }
  .contactpagerightDown {
    margin-bottom: 10vh;
    align-items: center;
    /* background: #000; */
    width: auto;
    padding: 0%;
  }
}
