.header-container {
  height: 10vh;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  margin-bottom: 10px;
}

.block {
  padding-left: 60px;
  transform: rotate(-90deg);
}

.logo {
  height: 10vh;
  position: relative;
  left: -40px;
  /* margin-left: 3vh; */
  color: var(--primary-color);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.logo > img{
  height: 20vh;
}

@media only screen and (max-device-width: 940px) 
{
  .logo{
    width: 80%;
    left: -22px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .logo > img{
    height: 20vh;
  }
}
