.mobile_main_container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.mobile_title_container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.mobile_title_container > .mobile_title_sub_container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

.mobile_title_sub_container > .mobile_title {
  font-size: var(--heading-font-size);
  font-weight: bold;
  color: var(--primary-color);
  font-family: var(--primary-font-family);
}

.mobile_title_sub_container > img {
  width: 35%;
  height: 35%;
}

.mobile_title_container > .mobile_title_bg_box {
  height: 3vh;
  width: 100%;
  background-color: #f3f3f3;
  opacity: 0.5;
  display: flex;
  margin-top: -1.5vh;
}

.mobile_main_container > .mobile_middle_content_container {
  margin-top: 5%;
  margin-bottom: 5%;
  width: 90%;
  height: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.mobile_middle_content_container > img {
  width: 35%;
  height: 35%;
  margin-bottom: 25px;
}

.mobile_middle_content_container > .mobile_middle_container_content {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.mobile_middle_container_content > .container_content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding-bottom: 5%;
}

.mobile_img > img {
  width: 3vw;
  height: 3vh;
  margin-left: 10px;
  padding-bottom: 1vh;
}

.container_content > .mobile_subtitle_content {
  color: #666666;
  font-size: var(--paragraph-font-size);
  font-weight: normal;
  font-family: var(--primary-font-family);
  padding-bottom: 2%;
  text-align: justify;
  text-justify: inter-character;
}

/* .mobile_about {
  margin-left: 10%;
  margin-right: 10%;
  margin-bottom: 5%;
  color: #666666;
  font-size: 2vh;
  align-items: justify;
  display: flex;
  flex-direction: center;
  justify-content: center;
} */

@media only screen and (max-device-width: 768px) 
{
  .mobile_title_container
  {
    width: auto;
    left: 0%;
    text-align: center;
  }

  .mobile_middle_content_container
  {
    flex-direction: column;
  }

  .mobile_main_container > .mobile_middle_content_container > img
  {
    width: 80%;
    height: 60%;
    margin-bottom: 20px;
    margin-top: 10px;
    margin-right: 10px;
  }

  .mobile_about
  {
    display: none;
  }

  .mobile_title_sub_container > .mobile_title
  {
    margin-left: 5px;
  }
  

  .container_content > img
  {
    width: 7vw;
    height: 7vh;
  }

  .container_content > .mobile_subtitle_content
  {
    font-size: 1.5vh;
  }

  .mobile_middle_content_container > .mobile_middle_container_content
  {
    width: auto;
  }
  .mobile_main_container > .mobile_middle_content_container {
    width: 90%;
  }
}