.webdev_main_container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.webdev_title_container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.webdev_title_container > .webdev_title_sub_container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

.webdev_title_sub_container > .webdev_title {
  font-size: var(--heading-font-size);
  font-weight: bold;
  color: var(--primary-color);
  font-family: var(--primary-font-family);
}

.webdev_title_sub_container > img {
  width: 4vw;
  height: 4vh;
}

.webdev_title_container > .webdev_title_bg_box {
  height: 3vh;
  width: 100%;
  background-color: #f3f3f3;
  opacity: 0.5;
  display: flex;
  margin-top: -1.5vh;
}

.webdev_main_container > .web_middle_content_container {
  margin-top: 5%;
  margin-bottom: 5%;
  width: 90%;
  height: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.web_middle_content_container > img {
  width: 35%;
  height: 35%;
}

.web_middle_content_container > .web_middle_container_content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 5vh;
}

.web_middle_container_content > .container_content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 5%;
  text-align: justify;
}

.container_content > .webdev_subtitle_container {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: row;
}

.webdev_subtitle_container > .webdev_subtitle {
  color: #666666;
  font-size: 3vh;
  font-weight: normal;
  padding-bottom: 1vh;
  font-family: var(--primary-font-family);
}

.webdev_subtitle_container > img {
  width: 5vw;
  height: 4vh;
  padding-right: 0.5vw;
}

.container_content > .webdev_subtitle_content {
  color: #666666;
  font-size: var(--paragraph-font-size);
  font-weight: normal;
  font-family: var(--primary-font-family);
  /* width: 70%; */
}

.webdev_about {
  margin-left: 10%;
  margin-right: 10%;
  margin-bottom: 5%;
  color: #666666;
  font-size: var(--paragraph-font-size);
  align-items: center;
  display: flex;
  flex-direction: center;
  justify-content: center;
}


@media only screen and (max-device-width: 768px)
{
  .webdev_main_container
  {
    margin-bottom: 10vh;
  }
  .webdev_title_container
  {
    left: 0;
    width:auto;
  }

  .webdev_main_container > .web_middle_content_container
  {
    flex-direction: column;
  }

  .webdev_main_container > .web_middle_content_container > img
  {
    width: 80%;
    height: 60%;
    margin-bottom: 20px;
  }

  .container_content > .webdev_subtitle_content
  {
    width: auto;
    font-size: 1.5vh;
  }
  .webdev_title_sub_container > img {
    width: 8vw;
    height: 4vh;
    margin-right: 10px;
  }
  
}
