@import url(https://fonts.google.com/specimen/Poppins#standard-styles);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root
{
    /* colors */
    --primary-color : #5ea430;

    /* footer */
    --footer-primary-bg-color : #1b6296;
    --footer-secondary-bg-color : #004273;

    /* fonts */
    --primary-font-family : "Poppins", sans-serif;
    --heading-font-size : 3vh;
    --paragraph-font-size : 2vh;
}

#bold
{
  font-weight: bold;
}

#error
{
  color: red;
}
@-webkit-keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes slideIn {
  0% {
    -webkit-transform: translateY(50%);
            transform: translateY(50%);
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

@keyframes slideIn {
  0% {
    -webkit-transform: translateY(50%);
            transform: translateY(50%);
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

@-webkit-keyframes main {
  0% {
    -webkit-transform: scaleX(0);
            transform: scaleX(0);
  }
  100% {
    -webkit-transform: scaleX(-1);
            transform: scaleX(-1);
  }
}

@keyframes main {
  0% {
    -webkit-transform: scaleX(0);
            transform: scaleX(0);
  }
  100% {
    -webkit-transform: scaleX(-1);
            transform: scaleX(-1);
  }
}

/* sidebar Nav */
.menuContainer {
  position: fixed;
  transition: background 1s;
  will-change: background;
  z-index: 10;
  cursor: pointer;
  background-color: var(--primary-color);
  box-shadow: 0px 0px 10px #00000029;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  opacity: 1;
  color: white;
  padding-bottom: 10px;
  padding-top: 15px;
  padding-right: 30px;
  padding-left: 30px;
  margin-top: 23px;
  margin-left: -30px;
  font-size: 2vh;
  font-family: var(--primary-font-family);
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
}

.menuContainer:hover {
  background-color: #587759;
  color: white;
}

.menuContainer.close {
  background-color: #363636;
}

.main_menu_container {
  position: fixed;
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: 9;
  width: 100%;
  height: 0vh;
  background: #252525;
  transition: all 0.25s ease-in;
}

.menu_sub_container
{
  height: 100%;
  width: 100vw;
  overflow: scroll;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5vh;
}

.menu_sub_container::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.menu_sub_container {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.main_menu_container.show_menu {
  height: 100%;
  transition: all 0.5s ease-out;
}

.main_menu_container > .menu_sub_container > .left_column_container {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 80vw;
  height: 80%;
  margin-left: 5vh;
}

.left_column_container > .menu_container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-right: 50px;
}


.menu_footer_inner_container_heading {
  color: var(--primary-color);
  display: flex;
  align-items: center;
  font-size: 2.8vh;
  font-weight: 800;
  margin-bottom: 20px;
  font-family: var(--primary-font-family);
}

.menu_inner_container_content
{
  color: #727271;
  font-size: 2.2vh;
  font-weight: 500;
  font-family: var(--primary-font-family);
  margin-left: 30px;
}

.menu_content_text {
  margin-bottom: 10px;
  cursor: pointer;
}

.menu_container > .menu_item_container {
  opacity: 0;
  -webkit-animation: appear 1s forwards;
          animation: appear 1s forwards;
  -webkit-animation-delay: 0.5ms;
          animation-delay: 0.5ms;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.menu_item_container > .menu_item {
  color: white;
  font-family: var(--primary-font-family);
  font-size: var(--heading-font-size);
  padding-bottom: 30px;
  cursor: pointer;
  z-index: 2;
  transition: color 0.2s ease-in-out;
  -webkit-animation: slideIn 1s forwards;
          animation: slideIn 1s forwards;
  -webkit-animation-delay: 1ms;
          animation-delay: 1ms;
  flex-direction: column;
}

.menu_item:hover {
  color: var(--primary-color);
}

.HashLink {
  color: inherit;
  text-decoration: inherit;
}

.menu_item::after {
  content: "→";
  position: absolute;
  padding-left: 15px;
  -webkit-transform: scale(1.5);
          transform: scale(1.5);
  opacity: 0;
  transition: 0.5s forwards;
}

.menu_item:hover .hoverbox {
  position: fixed;
  opacity: 0.2;
  float: left;
  background-color: grey;
  height: 30px;
  width: 100px;
  margin-top: -20px;
  margin-left: -150px;
  z-index: -1;
  -webkit-transform-origin: 100% 50%;
          transform-origin: 100% 50%;
  -webkit-animation: main 0.5s forwards ease-in-out;
          animation: main 0.5s forwards ease-in-out;
}

.menu_item:hover::after {
  opacity: 1;
}

.left_column_container > .social_container {
  display: flex;
  align-items: center;
  flex-direction: row;
  -webkit-animation: appear 3s forwards;
          animation: appear 3s forwards;
}

.menu_image
{
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-right: 50px;
}

.menu_inner_container_content > .menu_image > img {
  height: 3vh;
  width: 3vw;
  display: block;
  margin-left: -1vw;
  margin-right: 2vw;
  transition: color 0.3s ease-in-out;
  -webkit-animation: slideIn 2s forwards;
          animation: slideIn 2s forwards;
  cursor: pointer;
}

.main_menu_container > .right_column_container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 50%;
}

.right_column_container > .estimate_container {
  color: white;
  position: absolute;
  top: 0px;
  right: 0px;
  padding-top: 2%;
  padding-bottom: 1.5%;
  padding-left: 3%;
  padding-right: 5%;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  -webkit-animation: appear 2s forwards;
          animation: appear 2s forwards;
  transition: 0.3s ease-in-out;
}

.estimate_container > .message_us_button {
  color: black;
  transition: background 1s ease-out;
  will-change: background;
  cursor: pointer;
  background-color: #dfdfdf;
  margin-top: 5%;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  transition: color 0.3s ease-in-out;
  -webkit-animation: slideIn 2s forwards;
          animation: slideIn 2s forwards;
}

.right_column_container > .contact_container {
  position: absolute;
  bottom: 0px;
  right: 0px;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  width: 40%;
  padding-right: 2%;
  padding-bottom: 2%;
  -webkit-animation: appear 2s forwards;
          animation: appear 2s forwards;
}

.contact_container > .contact_item_container {
  display: flex;
  align-items: center;
  flex-direction: row;
  padding-right: 3vw;
  padding-bottom: 1.5vh;
  transition: color 0.3s ease-in-out;
  -webkit-animation: slideIn 2s forwards;
          animation: slideIn 2s forwards;
}

.contact_item_container > img {
  height: 1vh;
  width: 1vw;
  padding-right: 1vw;
}

.contact_item_container > .contact_content {
  font-family: var(--primary-font-family);
  font-size: 1vh;
  font-weight: normal;
  color: #929292;
}

.menu_footer_inner_container_heading1
  {
    color: var(--primary-color);
    display: flex;
    align-items: center;
    font-size: 1.8vh;
    font-weight: 800;
    margin-top: 30px;
    margin-bottom: 10px;
    font-family: var(--primary-font-family);
  }

@media only screen and (max-device-width: 768px) {
  .right_column_container > .estimate_container {
    display: none;
  }

  .main_menu_container > .menu_sub_container > .left_column_container
  {
    flex-direction: column;
    margin-top: 35vh;
  }

  .menu_footer_inner_container_heading1
  {
    color: var(--primary-color);
    display: flex;
    align-items: center;
    font-size: 1.8vh;
    font-weight: 800;
    margin-top: 30px;
    margin-bottom: 10px;
    font-family: var(--primary-font-family);
  }

  .menu_inner_container_content
  {
    margin-left: 0%;
    margin-bottom: 5vh;
  }
  
  .right_column_container > .contact_container {
    position: absolute;
    bottom: 10%;
    left: 15%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    width: 40%;
    padding-right: 2%;
    padding-bottom: 2%;
    -webkit-animation: appear 2s forwards;
            animation: appear 2s forwards;
  }

  .left_column_container > .social_container {
    position: absolute;
    padding-left: 15%;
    padding-bottom: 5%;
    top: 70vh;
    left: 0px;
    display: flex;
    align-items: center;
    flex-direction: row;
    -webkit-animation: appear 3s forwards;
            animation: appear 3s forwards;
  }
  
  .social_container > img {
    height: 4vh;
    width: 4vw;
    padding-left: 1vw;
    padding-right: 1vw;
    transition: color 0.3s ease-in-out;
    -webkit-animation: slideIn 2s forwards;
            animation: slideIn 2s forwards;
  }

  .menu_inner_container_content > .menu_image > img
  {
    width: 5vw;
    margin-right: 6vw;
  }
}

@media only screen and (max-device-width: 420px) {
  .main_menu_container > .menu_sub_container > .left_column_container
  {
    flex-direction: column;
    margin-top: 50vh;
  }

  .menu_inner_container_content
  {
    margin-left: 0%;
    margin-bottom: 5vh;
  }

  .right_column_container > .contact_container {
    bottom: 8%;
  }
}

@media only screen and (max-device-width: 320px) {
  /* .main_menu_container > .menu_sub_container > .left_column_container
  {
    margin-top: 80vh;
  } */
}
.header-container {
  height: 10vh;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  margin-bottom: 10px;
}

.block {
  padding-left: 60px;
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
}

.logo {
  height: 10vh;
  position: relative;
  left: -40px;
  /* margin-left: 3vh; */
  color: var(--primary-color);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.logo > img{
  height: 20vh;
}

@media only screen and (max-device-width: 940px) 
{
  .logo{
    width: 80%;
    left: -22px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .logo > img{
    height: 20vh;
  }
}

footer {
  height: 30vh;
  width: 100%;
  background: var(--footer-primary-bg-color);
}

.up {
  height: 32vh;
  width: 100%;
  background: var(--footer-primary-bg-color);
  display: flex;
  align-items: center;
  justify-content: center;
}

.down {
  height: 5vh;
  width: 100%;
  background: var(--footer-secondary-bg-color);
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.footer_up_contaoner_wrap {
  height: 100%;
  width: 75%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer_inner_container {
  height: 80%;
  width: 25%;
  margin: 10px;
}

.footer_inner_container_heading {
  height: 25%;
  width: 100%;
  color: white;
  display: flex;
  align-items: center;
  font-size: 1.8vh;
  font-weight: 800;
  font-family: var(--primary-font-family);
}

.footer_inner_container_content {
  height: 75%;
  width: 100%;
  color: rgb(235, 235, 235);
  font-size: 1.5vh;
  font-weight: 500;
  font-family: var(--primary-font-family);
}

.footer_content_text {
  margin-bottom: 10px;
  cursor: pointer;
}

.footer_contact_component {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.footer_contact_component > img {
  height: 30px;
  margin-right: 4px;
}

.footer_contact_component > .email {
  font-size: 1.5vh;
  color: white;
  font-weight: 500;
  font-family: var(--primary-font-family);
  cursor: pointer;
}

.footer_icon {
  width: 10%;
  height: 2vh;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: rgb(235, 235, 235);
  font-size: 1vh;
  font-weight: 800;
  font-family: var(--primary-font-family);
}

.footer_icon > img {
  height: 2vh;
  margin-left: 15px;
  cursor: pointer;
}

.copyright {
  width: 50%;
  height: 2vh;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: rgb(235, 235, 235);
  font-size: 1.5vh;
  font-weight: 500;
  font-family: var(--primary-font-family);
  cursor: pointer;
}

@media only screen and (max-device-width: 768px) {
  .down
  {
    width: auto;
    flex-direction: column;
    padding: 10px;
  }

  /* footer {
    height: 5vh;
    position: fixed;
    bottom: 0;
    left: 0;
  } */

  .up {
    /* display: none; */
    height: auto;
  }

  .footer_up_contaoner_wrap
  {
    flex-direction: column;
  }

  .footer_content_text
  {
    margin-bottom: 5px;
  }

  .footer_inner_container_heading
  {
    width: auto;
    margin-bottom: 10px;
  }

  .footer_inner_container_content
  {
    text-align: center;
  }

  .footer_inner_container
  {
    width: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .footer_contact_component
  {
    flex-direction: column;
  }

  .footer_icon {
    width: auto;
  }
  .copyright {
    width: auto;
  }
}

/* =>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=> */
/* Desktop version Starts here */
/* =>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=> */

/* Home-page starts here */

.heading_container,
.home_email_container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.home-container {
  width: 100%;
  color: var(--primary-color);
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 10vh;
}

.heading_container {
  width: 50%;
  color: var(--primary-color);
  font-size: var(--heading-font-size);
  font-weight: 700;
  text-align: center;
  font-family: var(--primary-font-family);
  text-transform: uppercase;
}

.home_email_container {
  margin-top: 10px;
}

.home_email_container > img {
  height: 3vh;
  margin-right: 4px;
}

.home_email_container > .email{
  display: block;
  text-decoration: none;
  font-style: normal;
  font-size: 2.5vh;
  color: #666666;
}

.main_image_container {
  height: 70vh;
  margin-top: 5vh;
  width: 100%;
  background: #f2f2f2 0 0 no-repeat padding-box;
  /* box-shadow: 0 0 10px #00000029; */
  opacity: 1;
}

.main_image_container > img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

/* Home-page ends here */

/* --------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */

/* Service-page starts here */

.service_container,
.heading_container,
.service_box_container,
.box,
.icon,
.box_title,
.box_content {
  display: flex;
  align-items: center;
}

.service_container,
.box_content {
  justify-content: flex-start;
}

.service_container,
.box,
.heading_container {
  flex-direction: column;
}

.service_container {
  width: 100%;
  margin-bottom: 20px;
}

.heading_container {
  justify-content: center;
}

.service_title {
  color: var(--primary-color);
  font-size: var(--heading-font-size);
  font-weight: 700;
  text-align: center;
  font-family: var(--primary-font-family);
}

.service_content {
  color: #666666;
  font-size: var(--paragraph-font-size);
  font-weight: 400;
  margin-top: 10px;
  text-align: center;
  width: 65%;
  font-family: var(--primary-font-family);
}

.service_box_container {
  width: 90%;
  margin-top: 20px;
  justify-content: center;
  flex-wrap: wrap;
}

.box {
  width: 20%;
  min-height: 40vh;
  padding: 10px;
  margin: 20px;
  border-radius: 30px;
  background: linear-gradient(145deg, #e6e6e6, #ffffff);
  box-shadow:  20px 20px 60px #d9d9d9,
             -20px -20px 60px #ffffff;
  opacity: 1;
  justify-content: center;
  cursor: pointer;
}

.icon {
  height: 18vh;
  width: 95%;
  padding-left: 10px;
  justify-content: center;
  /* background-color: tomato; */
}

.icon > img {
  width: 90%;
  height: 100%;
}

.box_title {
  width: 95%;
  padding-left: 10px;
  color: #000000;
  font-size: 3vh;
  font-weight: bold;
  margin-top: 5px;
  text-align: center;
  justify-content: center;
}

.box_content {
  width: 95%;
  padding: 10px;
  color: #666666;
  font-size: var(--paragraph-font-size);
  font-weight: 400;
  margin-top: 5px;
  text-align: center;
}

/* Service-page ends here */

/* --------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */

/* contact-page starts here */

.contact_us_container,
.container_wrap {
  display: flex;
  align-items: center;
  justify-content: center;
}

.contact_us_subheading,
.contact_us_content,
.label {
  font-weight: 400;
  margin-top: 10px;
  font-family: var(--primary-font-family);
}

.contact_us_container {
  height: 100vh;
  width: 100%;
  /* margin-top: 10vh; */
}

.container_wrap {
  height: 95%;
  width: 80%;
}

.left_column {
  height: 100%;
  width: 50%;
}

.right_column {
  height: 100%;
  width: 50%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.contact_us_title {
  color: var(--primary-color);
  font-size: var(--heading-font-size);
  font-weight: bold;
  font-family: var(--primary-font-family);
}

.contact_us_subheading {
  color: #000000;
  font-size: 2vh;
}

.contact_us_content {
  color: #000000;
  font-size: 2vh;
}

.form_container_warp {
  min-width: 70%;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  padding: 20px;
  border-radius: 20px;
  background: linear-gradient(145deg, #e6e6e6, #ffffff);
  box-shadow:  20px 20px 60px #d9d9d9,
             -20px -20px 60px #ffffff;
}

.input_container {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin: 20px;
  border-radius: 50px;
}

form {
  width: 90%;
}

.form__field {
  height: 4vh;
  width: 90%;
  border: 1px solid #dedede;
  opacity: 1;
  padding: 10px;
  border-radius: 20px;
  background: linear-gradient(145deg, #e6e6e6, #ffffff);
  box-shadow:  10px 10px 20px #d9d9d9,
             -10px -10px 20px #ffffff;
}

.text_area {
  height: 7vh;
  width: 90%;
  padding: 10px;
  border: 1px solid #dedede;
  opacity: 1;
  border-radius: 30px;
  background: linear-gradient(145deg, #e6e6e6, #ffffff);
  box-shadow:  20px 20px 60px #d9d9d9,
             -20px -20px 60px #ffffff;
}

.label {
  color: #666666;
  font-size: var(--paragraph-font-size);
}

.button_position {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 10px;
  margin-bottom: 10px;
  margin-top: 10px;
}
.submit {
  font-size: var(--paragraph-font-size);
  font-weight: 500;
  color: white;
  background: #5ea430;
  border: 0;
  padding: 5px 10px 5px 10px;
  border-radius: 5px;
  font-family: var(--primary-font-family);
}


/* contact-page ends here */

/* --------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */

/* =>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=> */
/* Desktop version ends here */
/* =>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=> */

/* --------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */

/* style sheet for tablet and mobile on and below 768 pixels */

/* =>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=> */
/* Mobile version starts here */
/* =>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=> */

@media only screen and (max-device-width: 768px) {
  /* Home-page starts here */
  .heading_container {
    font-size: 3vh;
    width: 70%;
  }

  .main_image_container > img {
    object-fit: contain;
  }

  .main_image_container {
    height: auto;
  }
  /* Home-page ends here */

  /* --------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */

  /* Service-page starts here */
  .box {
    width: 70%;
  }

  .icon > img {
    width: 55%;
  }

  .service_content
  {
    width: 100%;
  }
  /* Service-page ends here */

  /* --------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */

  /* Contact-page starts here */
  .contact_us_container {
    height: 120vh;
    margin-bottom: 20vh;
  }

  .container_wrap {
    flex-direction: column;
    margin-top: 0;
  }
  .left_column {
    height: auto;
    width: 90%;
    text-align: center;
  }
  .right_column {
    height: 100%;
    width: 100%;
    margin-top: 40px;
  }
  .form_container_warp {
    min-width: 100%;
  }
  .form__field {
    height: 5vh;
    width: 100%;
  }
  .label {
    font-size: 12px;
  }
  .recapt
  {
    width: 4vw;
  }
  #lottie-arrow
  {
    display: none;
  }
  
  
  /* Contact-page ends here */
}

/* =>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=> */
/* Mobile version ends here */
/* =>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=> */


/* cookies popup */


body {
  font-family: var(--primary-font-family);
}

#simple-cookie-consent {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  resize: vertical;
  overflow: auto;
  background: rgba(0, 0, 0, 0);
}
#simple-cookie-consent .cookie-consent-container {
  position: absolute;
  top: 80%;
  left: 2%;
  margin: -100px auto 0;
  background: rgb(83, 83, 83);
  padding: 20px;
  max-width: 500px;
  color:rgb(255, 255, 255);
  border-radius:10px;
}
#simple-cookie-consent .cookie-consent-selection {
  text-align: right;
}
#simple-cookie-consent button {
  border: none;
  padding: 10px 20px;
  margin: 10px 0 0 10px;
  background: none;
  font-size: 1.1em;
}
#simple-cookie-consent button.cookie-consent-allow {
  background-color: var(--primary-color);
  color: #fff;
  font-weight:bold;
}
#simple-cookie-consent button.cookie-consent-allow:focus,
#simple-cookie-consent button.cookie-consent-allow:hover {
  background-color: #059862;
  cursor: pointer;
}
#simple-cookie-consent button.cookie-consent-deny {
  padding: 5px 0;
  font-size: 0.9em;
  opacity: 0.8;
}
#simple-cookie-consent button.cookie-consent-deny:focus,
button.cookie-consent-deny:hover {
  opacity: 1;
  cursor: pointer;
}

.career_main_container {
  height: 60vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
}

.career_title_container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.career_title {
  color: var(--primary-color);
  font-size: var(--heading-font-size);
  font-weight: bold;
  font-family: var(--primary-font-family);
}

.career_title_container > img {
  width: 10vw;
  height: 10vh;
}

.career_content {
  color: #666666;
  font-size: var(--paragraph-font-size);
  font-weight: 400;
  margin-top: 10px;
  text-align: center;
  width: 30%;
  font-family: var(--primary-font-family);
}

.email_container {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.email_container > img {
  height: 2.5vh;
  margin-right: 4px;
}

.email_container > .email {
  font-size: var(--paragraph-font-size);
  color: #666666;
}

.career_box_container {
  width: 100%;
  margin-top: 40px;
  padding-top: 10px;
  display: flex;
  flex-direction: flex-start;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
}

.career_box {
  height: 10vh;
  width: 30%;
  margin: 10px;
  display: flex;
  flex-direction: flex-;
  align-items: flex-start;
  justify-content: center;
  background: ffffff 0 0 no-repeat padding-box;
  background: #ffffff 0 0 no-repeat padding-box;
  box-shadow: 0 0 5px #00000029;
  border: 1px solid #e8e8e8;
  border-radius: 5px;
  opacity: 1;
}

.LPOB {
  height: 100%;
  width: 60%;
  display: flex;
  padding-left: 10px;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
}

.career_box_title {
  color: #050505;
  font-size: 1.5vh;
  font-weight: bold;
  margin-top: 10px;
}

.career_box_subheading {
  color: #666666;
  font-size: 1.5vh;
  font-weight: bold;
  margin-top: 10px;
}

.RPOB {
  height: 100%;
  width: 40%;
  display: flex;
  padding-left: 10px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.RPOB > button {
  background: var(--unnamed-color-ffffff) 0 0 no-repeat padding-box;
  border: 1px solid var(--logo-color-1b6296);
  background: #ffffff 0 0 no-repeat padding-box;
  border: 1px solid #1b6296;
  border-radius: 5px;
  opacity: 1;
  font-size: var(--paragraph-font-size);
  color: #1b6296;
  cursor: pointer;
}

@media only screen and (max-device-width: 768px) {
  .career_main_container {
    height: 100vh;
  }
  .career_content {
    width: 90%;
  }
  .career_box_container {
    padding: 0;
  }
  .career_box {
    width: 90%;
  }
}

.about_body {
  width: 100%;
  margin-bottom: 20vh;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.about_content_wrap {
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
}

.about_title {
  color: var(--primary-color);
  font-size: var(--heading-font-size);
  font-weight: bold;
  font-family: var(--primary-font-family);
}

.about_main-img {
  margin-top: 5vh;
  margin-bottom: 2vh;
}

.about_main-img > img {
  height: 50vh;
  width: 50vh;
}

.about_main_content {
  color: #666666;
  font-size: var(--paragraph-font-size);
  font-weight: normal;
  font-family: var(--primary-font-family);
  padding-bottom: 2%;
  text-align: justify;
}

.about_box {
  margin-top: 10px;
  width: 80%;
  padding: 10px;
  border: 1px dashed #6494aa;
  border-radius: 10px;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.POABL {
  width: 50%;
  margin-left: 10px;
  background: rgb(255, 255, 255);
  color: #666666;
  font-size: var(--paragraph-font-size);
  font-weight: 200;
  font-family: var(--primary-font-family);
}

.POABR {
  width: 50%;
  height: 20vh;

  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.about_us_box {
  height: 20vh;
  width: 60%;
  border-radius: 10px;
  /* background-color: #666666; */
}

.about_us_box > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

@media only screen and (max-device-width: 768px) {
  .about_content_wrap {
    margin-bottom: 10px;
    height: auto;
    width: 90%;
  }
  .about_box {
    flex-direction: column-reverse;
  }

  .POABR {
    width: 100%;
    justify-content: center;
  }
  .about_us_box {
    width: 100%;
  }
  .POABL {
    margin-top: 10px;
    width: 100%;
    font-size: 1.5vh;
  }
}

.uiux_main_container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  margin-bottom: 10vh;
}

.uiux_title_container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.uiux_title_container > .uiux_title_sub_container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

.uiux_title_sub_container > .uiux_title {
  font-size: var(--heading-font-size);
  font-weight: bold;
  color: var(--primary-color);
  font-family: var(--primary-font-family);
}

.uiux_title_sub_container > img {
  width: 2vw;
  height: 2vh;
}

.uiux_title_container > .uiux_title_bg_box {
  height: 3vh;
  background-color: #f3f3f3;
  opacity: 0.5;
  display: flex;
  margin-top: -1.5vh;
}

.uiux_main_container > .ux_middle_content_container {
  margin-top: 5%;
  margin-bottom: 5%;
  width: 90%;
  height: 50%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
}

.ux_middle_content_container > img {
  width: 35%;
  height: 35%;
}

.ux_middle_content_container > .ux_middle_container_content {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 5vh;
}

.ux_middle_container_content > .uiux_content {
  /* padding-left: 2%; */
  padding-bottom: 4%;
  color: #666666;
  font-size: var(--paragraph-font-size);
  text-align: justify;
  font-weight: normal;
  font-family: var(--primary-font-family);
}


@media only screen and (max-device-width: 768px) 
{
  .uiux_main_container
  {
    justify-content:flex-start; 
  }
  .uiux_title_container
  {
    left: 0%;
    width: auto;
  }
  .uiux_title_bg_box
  {
    margin-bottom: 10px;
  }
  .uiux_main_container > .ux_middle_content_container
  {
    width: 100%;
    flex-direction: column;
  }
  .uiux_main_container > .ux_middle_content_container > img
  {
    width: 80%;
    height: 60%;
    margin-bottom: 20px;
    margin-top: 10px;
  }

  .uiux_main_container > .ux_middle_content_container > .ux_middle_container_content
  {
    width: 90%;
  }

  .ux_middle_container_content > .uiux_content
  {
    font-size: 1.5vh;
  }

  .uiux_title_sub_container > img {
    width: 7vw;
    height: 4vh;
    margin-right: 10px;
  }
}
.webdev_main_container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.webdev_title_container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.webdev_title_container > .webdev_title_sub_container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

.webdev_title_sub_container > .webdev_title {
  font-size: var(--heading-font-size);
  font-weight: bold;
  color: var(--primary-color);
  font-family: var(--primary-font-family);
}

.webdev_title_sub_container > img {
  width: 4vw;
  height: 4vh;
}

.webdev_title_container > .webdev_title_bg_box {
  height: 3vh;
  width: 100%;
  background-color: #f3f3f3;
  opacity: 0.5;
  display: flex;
  margin-top: -1.5vh;
}

.webdev_main_container > .web_middle_content_container {
  margin-top: 5%;
  margin-bottom: 5%;
  width: 90%;
  height: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.web_middle_content_container > img {
  width: 35%;
  height: 35%;
}

.web_middle_content_container > .web_middle_container_content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 5vh;
}

.web_middle_container_content > .container_content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 5%;
  text-align: justify;
}

.container_content > .webdev_subtitle_container {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: row;
}

.webdev_subtitle_container > .webdev_subtitle {
  color: #666666;
  font-size: 3vh;
  font-weight: normal;
  padding-bottom: 1vh;
  font-family: var(--primary-font-family);
}

.webdev_subtitle_container > img {
  width: 5vw;
  height: 4vh;
  padding-right: 0.5vw;
}

.container_content > .webdev_subtitle_content {
  color: #666666;
  font-size: var(--paragraph-font-size);
  font-weight: normal;
  font-family: var(--primary-font-family);
  /* width: 70%; */
}

.webdev_about {
  margin-left: 10%;
  margin-right: 10%;
  margin-bottom: 5%;
  color: #666666;
  font-size: var(--paragraph-font-size);
  align-items: center;
  display: flex;
  flex-direction: center;
  justify-content: center;
}


@media only screen and (max-device-width: 768px)
{
  .webdev_main_container
  {
    margin-bottom: 10vh;
  }
  .webdev_title_container
  {
    left: 0;
    width:auto;
  }

  .webdev_main_container > .web_middle_content_container
  {
    flex-direction: column;
  }

  .webdev_main_container > .web_middle_content_container > img
  {
    width: 80%;
    height: 60%;
    margin-bottom: 20px;
  }

  .container_content > .webdev_subtitle_content
  {
    width: auto;
    font-size: 1.5vh;
  }
  .webdev_title_sub_container > img {
    width: 8vw;
    height: 4vh;
    margin-right: 10px;
  }
  
}

.mobile_main_container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.mobile_title_container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.mobile_title_container > .mobile_title_sub_container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

.mobile_title_sub_container > .mobile_title {
  font-size: var(--heading-font-size);
  font-weight: bold;
  color: var(--primary-color);
  font-family: var(--primary-font-family);
}

.mobile_title_sub_container > img {
  width: 35%;
  height: 35%;
}

.mobile_title_container > .mobile_title_bg_box {
  height: 3vh;
  width: 100%;
  background-color: #f3f3f3;
  opacity: 0.5;
  display: flex;
  margin-top: -1.5vh;
}

.mobile_main_container > .mobile_middle_content_container {
  margin-top: 5%;
  margin-bottom: 5%;
  width: 90%;
  height: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.mobile_middle_content_container > img {
  width: 35%;
  height: 35%;
  margin-bottom: 25px;
}

.mobile_middle_content_container > .mobile_middle_container_content {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.mobile_middle_container_content > .container_content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding-bottom: 5%;
}

.mobile_img > img {
  width: 3vw;
  height: 3vh;
  margin-left: 10px;
  padding-bottom: 1vh;
}

.container_content > .mobile_subtitle_content {
  color: #666666;
  font-size: var(--paragraph-font-size);
  font-weight: normal;
  font-family: var(--primary-font-family);
  padding-bottom: 2%;
  text-align: justify;
  text-justify: inter-character;
}

/* .mobile_about {
  margin-left: 10%;
  margin-right: 10%;
  margin-bottom: 5%;
  color: #666666;
  font-size: 2vh;
  align-items: justify;
  display: flex;
  flex-direction: center;
  justify-content: center;
} */

@media only screen and (max-device-width: 768px) 
{
  .mobile_title_container
  {
    width: auto;
    left: 0%;
    text-align: center;
  }

  .mobile_middle_content_container
  {
    flex-direction: column;
  }

  .mobile_main_container > .mobile_middle_content_container > img
  {
    width: 80%;
    height: 60%;
    margin-bottom: 20px;
    margin-top: 10px;
    margin-right: 10px;
  }

  .mobile_about
  {
    display: none;
  }

  .mobile_title_sub_container > .mobile_title
  {
    margin-left: 5px;
  }
  

  .container_content > img
  {
    width: 7vw;
    height: 7vh;
  }

  .container_content > .mobile_subtitle_content
  {
    font-size: 1.5vh;
  }

  .mobile_middle_content_container > .mobile_middle_container_content
  {
    width: auto;
  }
  .mobile_main_container > .mobile_middle_content_container {
    width: 90%;
  }
}
.digital_main_container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 20vh;
}

#seo, #sem, #Email, #smm
{
  width: 90%;
}

.digital_title_container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.digital_title_container > .digital_title_sub_container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  margin-bottom: 5vh;
}

.digital_title_sub_container > .digital_title {
  font-size: var(--heading-font-size);
  font-weight: bold;
  color: var(--primary-color);
  font-family: var(--primary-font-family);
}

.digital_title_sub_container > img {
  width: 4vw;
  height: 4vh;
}

.digital_title_container > .digital_title_bg_box {
  height: 3vh;
  width: 90%;
  background-color: #f3f3f3;
  opacity: 0.5;
  display: flex;
  margin-top: -1.5vh;
}

.middle_container_content {
  margin-top: 5%;
  margin-bottom: 5%;
  height: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* flex-direction: row; */
}
.digital_middle_content_container
{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 70%;
}

.digital_middle_content_container > img {
  width: 30%;
  height: 40%;
  margin-top: 5vh;
}

.digital_middle_content_container > .middle_container_content {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.digital_middle_content_container > .digital_content_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.digital_content_container > .digital_content_title {
  font-size: 3vh;
  color: #666666;
  padding-bottom: 1%;
  text-align: center;
  font-weight: normal;
  font-family: var(--primary-font-family);
}

.digital_content_container > .digital_content {
  padding-bottom: 4%;
  color: #666666;
  font-size: var(--paragraph-font-size);
  text-align: justify;
  font-weight: normal;
  font-family: var(--primary-font-family);
}


@media only screen and (max-device-width: 768px)
{
  .digital_middle_content_container > .digital_middle_container_content
  {
    margin-top: 20px;
  }

  .digital_title_sub_container > img {
    width: 7vw;
    height: 4vh;
    margin-right: 10px;
  }

  .digital_title_container
  {
    width: auto;
    left: 0%;
  }

  .digital_main_container > .digital_middle_content_container > img
  {
    width: 60%;
    height: 60%;
  }
  .digital_content_container > .digital_content
  {
    font-size: 1.5vh;
  }
}
.career_form_container
{
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    margin-bottom: 5vh;
}

.career_form_title
{
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--primary-color);
    font-size: 3vh;
    font-weight: bold;
    font-family: var(--primary-font-family);
    text-align: center;
    text-transform: uppercase;
}

.form_container_warp_career {
    min-width: 40%;
    background: #f3f3f3 0% 0% no-repeat padding-box;
    border-radius: 10px;
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 20px;
    border-radius: 20px;
    background: linear-gradient(145deg, #e6e6e6, #ffffff);
    box-shadow:  20px 20px 60px #d9d9d9,
             -20px -20px 60px #ffffff;
  }

  .recapt
  {
    width: 100%;
    height: 10vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .input_container {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    margin: 20px;
  }

  .input_radio_container
  {
    margin: 20px;
  }
  
  form {
    width: 90%;
  }

  input[type=radio] {
    border: 0px;
    height: 20px;
    width: 20px;
    margin: 2vh;
}
  
  .form__field {
    height: 4vh;
  width: 90%;
  border: 1px solid #dedede;
  opacity: 1;
  padding: 10px;
  border-radius: 20px;
  background: linear-gradient(145deg, #e6e6e6, #ffffff);
  box-shadow:  20px 20px 60px #d9d9d9,
             -20px -20px 60px #ffffff;
  }

  
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }

  /* switch */
  
  .text_area {
    height: 7vh;
    width: 90%;
    padding: 10px;
    border: 1px solid #dedede;
    opacity: 1;
    border-radius: 30px;
    background: linear-gradient(145deg, #e6e6e6, #ffffff);
    box-shadow:  20px 20px 60px #d9d9d9,
               -20px -20px 60px #ffffff;
  }
  
  .label {
    color: #666666;
    font-size: var(--paragraph-font-size);
  }

  .radio_label {
    color: var(--primary-color);
    font-size: 2.7vh;
    font-weight: bold;
    font-family: var(--primary-font-family);
  }

  input{
    color: #666666;
    font-size: var(--paragraph-font-size);
    font-weight: 200;
    font-family: var(--primary-font-family);
  }
  textarea
  {
    color: #666666;
    font-size: var(--paragraph-font-size);
    font-weight: 200;
    font-family: var(--primary-font-family);
  }
  
  .button_position {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    margin-bottom: 10px;
  }
  .submit {
    font-size: var(--paragraph-font-size);
    font-weight: 500;
    color: white;
    background: var(--primary-color);
    border: 0;
    padding: 5px 10px 5px 10px;
    border-radius: 5px;
    font-family: var(--primary-font-family);
  }


  @media only screen and (max-device-width: 768px) {

    .career_form_body
    {
        margin-bottom: 40vh;
    }
    .form_container_warp_career
    {
        min-width: 80%;
        
    }

  }
.body {
  width: 100vw;
  margin-bottom: 10vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.contactpageTitle {
  width: 100%;
  text-align: center;
  font-size: var(--heading-font-size);
  font-weight: bold;
  color: var(--primary-color);
  font-family: var(--primary-font-family);
}

.contactpage_left {
  height: 100%;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contactpage_right {
  height: 100%;
  width: 50%;
  /* background: rgb(255, 0, 0); */
  display: flex;
  flex-direction: column;
}

.contactpagerightDown iframe {
  width: 80%;
  height: 40vh;
}

.contactpagerightUp {
  height: 50%;
  /* background: rgb(8, 255, 140); */
  padding: 30px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.contactadressContainer {
  display: flex;
}

.contactpageaddressWarp {
  margin: 20px;
  padding: 10px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(145deg, #e6e6e6, #ffffff);
  box-shadow: 20px 20px 60px #d9d9d9, -20px -20px 60px #ffffff;
}

.contactpagerightDown {
  height: 50%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 30px;
  /* background: rgb(8, 24, 255); */
}

/* form style */

.form_container_warp {
  width: 50%;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  padding: 20px;
  border-radius: 20px;
  background: linear-gradient(145deg, #e6e6e6, #ffffff);
  box-shadow: 20px 20px 60px #d9d9d9, -20px -20px 60px #ffffff;
}

.input_container {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 20px;
  border-radius: 50px;
}

form {
  width: 90%;
}

.form__field {
  height: 4vh;
  width: 90%;
  border: 1px solid #dedede;
  opacity: 1;
  padding: 10px;
  border-radius: 20px;
  background: linear-gradient(145deg, #e6e6e6, #ffffff);
  box-shadow:  20px 20px 60px #d9d9d9,
             -20px -20px 60px #ffffff;
}

.text_area {
  height: 7vh;
  width: 90%;
  padding: 10px;
  border: 1px solid #dedede;
  opacity: 1;
  border-radius: 30px;
  background: linear-gradient(145deg, #e6e6e6, #ffffff);
  box-shadow:  20px 20px 60px #d9d9d9,
             -20px -20px 60px #ffffff;
}

.label {
  color: #666666;
  font-size: var(--paragraph-font-size);
}

.label_ {
  color: #000000;
  font-size: var(--paragraph-font-size);
  font-family: var(--primary-font-family);
  text-transform: uppercase;
  font-weight: bold;
}

.button_position {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  margin-bottom: 10px;
}
.submit {
  font-size: var(--paragraph-font-size);
  font-weight: 500;
  color: white;
  background: var(--primary-color);
  border: 0;
  padding: 5px 10px 5px 10px;
  border-radius: 5px;
  font-family: var(--primary-font-family);
}

.contact_component {
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
}

.contact_component > img {
  height: 30px;
  margin-right: 15px;
}

.contact_component > .email {
  font-size: var(--paragraph-font-size);
  color: rgb(92, 92, 92);
  font-weight: 500;
  font-family: var(--primary-font-family);
}

.contact-container-position
{
  text-align: center;
  margin-bottom: 3vh;

}

@media only screen and (max-device-width: 768px) {
  .career_form_body {
    margin-bottom: 40vh;
  }

  .contactpageTitle {
    margin-bottom: 10px;
  }

  .body {
    flex-direction: column;
  }

  .contactpage_left {
    width: 90%;
  }

  .contactpage_right {
    width: 100%;
  }
  .contactadressContainer {
    flex-direction: column;
  }
  .contactpagerightDown iframe {
    width: 80%;
    height: 35vh;
  }
  .contactpagerightDown {
    margin-bottom: 10vh;
    align-items: center;
    /* background: #000; */
    width: auto;
    padding: 0%;
  }
}

section
{
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
}

.text-warp
{
    width: 60vw;
    height: -webkit-fit-content;
    height: fit-content;
    display: flex;
    flex-direction: column;
}

.TACHeading
{
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
}

.TACPara
{
    text-align: justify;
    margin-bottom: 10px;
}

@media only screen and (max-device-width: 768px) 
{
    .text-warp
    {
        margin-bottom: 10vh;
    }
}
section
{
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
}

.text-warp
{
    width: 60vw;
    height: -webkit-fit-content;
    height: fit-content;
    display: flex;
    flex-direction: column;
}

.TACHeading
{
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
}

.TACPara
{
    text-align: justify;
    margin-bottom: 10px;
}

@media only screen and (max-device-width: 768px) 
{
    .text-warp
    {
        margin-bottom: 10vh;
    }
}

