.about_body {
  width: 100%;
  margin-bottom: 20vh;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.about_content_wrap {
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
}

.about_title {
  color: var(--primary-color);
  font-size: var(--heading-font-size);
  font-weight: bold;
  font-family: var(--primary-font-family);
}

.about_main-img {
  margin-top: 5vh;
  margin-bottom: 2vh;
}

.about_main-img > img {
  height: 50vh;
  width: 50vh;
}

.about_main_content {
  color: #666666;
  font-size: var(--paragraph-font-size);
  font-weight: normal;
  font-family: var(--primary-font-family);
  padding-bottom: 2%;
  text-align: justify;
}

.about_box {
  margin-top: 10px;
  width: 80%;
  padding: 10px;
  border: 1px dashed #6494aa;
  border-radius: 10px;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.POABL {
  width: 50%;
  margin-left: 10px;
  background: rgb(255, 255, 255);
  color: #666666;
  font-size: var(--paragraph-font-size);
  font-weight: 200;
  font-family: var(--primary-font-family);
}

.POABR {
  width: 50%;
  height: 20vh;

  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.about_us_box {
  height: 20vh;
  width: 60%;
  border-radius: 10px;
  /* background-color: #666666; */
}

.about_us_box > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

@media only screen and (max-device-width: 768px) {
  .about_content_wrap {
    margin-bottom: 10px;
    height: auto;
    width: 90%;
  }
  .about_box {
    flex-direction: column-reverse;
  }

  .POABR {
    width: 100%;
    justify-content: center;
  }
  .about_us_box {
    width: 100%;
  }
  .POABL {
    margin-top: 10px;
    width: 100%;
    font-size: 1.5vh;
  }
}
