.digital_main_container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 20vh;
}

#seo, #sem, #Email, #smm
{
  width: 90%;
}

.digital_title_container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.digital_title_container > .digital_title_sub_container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  margin-bottom: 5vh;
}

.digital_title_sub_container > .digital_title {
  font-size: var(--heading-font-size);
  font-weight: bold;
  color: var(--primary-color);
  font-family: var(--primary-font-family);
}

.digital_title_sub_container > img {
  width: 4vw;
  height: 4vh;
}

.digital_title_container > .digital_title_bg_box {
  height: 3vh;
  width: 90%;
  background-color: #f3f3f3;
  opacity: 0.5;
  display: flex;
  margin-top: -1.5vh;
}

.middle_container_content {
  margin-top: 5%;
  margin-bottom: 5%;
  height: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* flex-direction: row; */
}
.digital_middle_content_container
{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 70%;
}

.digital_middle_content_container > img {
  width: 30%;
  height: 40%;
  margin-top: 5vh;
}

.digital_middle_content_container > .middle_container_content {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.digital_middle_content_container > .digital_content_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.digital_content_container > .digital_content_title {
  font-size: 3vh;
  color: #666666;
  padding-bottom: 1%;
  text-align: center;
  font-weight: normal;
  font-family: var(--primary-font-family);
}

.digital_content_container > .digital_content {
  padding-bottom: 4%;
  color: #666666;
  font-size: var(--paragraph-font-size);
  text-align: justify;
  font-weight: normal;
  font-family: var(--primary-font-family);
}


@media only screen and (max-device-width: 768px)
{
  .digital_middle_content_container > .digital_middle_container_content
  {
    margin-top: 20px;
  }

  .digital_title_sub_container > img {
    width: 7vw;
    height: 4vh;
    margin-right: 10px;
  }

  .digital_title_container
  {
    width: auto;
    left: 0%;
  }

  .digital_main_container > .digital_middle_content_container > img
  {
    width: 60%;
    height: 60%;
  }
  .digital_content_container > .digital_content
  {
    font-size: 1.5vh;
  }
}