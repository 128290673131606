footer {
  height: 30vh;
  width: 100%;
  background: var(--footer-primary-bg-color);
}

.up {
  height: 32vh;
  width: 100%;
  background: var(--footer-primary-bg-color);
  display: flex;
  align-items: center;
  justify-content: center;
}

.down {
  height: 5vh;
  width: 100%;
  background: var(--footer-secondary-bg-color);
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.footer_up_contaoner_wrap {
  height: 100%;
  width: 75%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer_inner_container {
  height: 80%;
  width: 25%;
  margin: 10px;
}

.footer_inner_container_heading {
  height: 25%;
  width: 100%;
  color: white;
  display: flex;
  align-items: center;
  font-size: 1.8vh;
  font-weight: 800;
  font-family: var(--primary-font-family);
}

.footer_inner_container_content {
  height: 75%;
  width: 100%;
  color: rgb(235, 235, 235);
  font-size: 1.5vh;
  font-weight: 500;
  font-family: var(--primary-font-family);
}

.footer_content_text {
  margin-bottom: 10px;
  cursor: pointer;
}

.footer_contact_component {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.footer_contact_component > img {
  height: 30px;
  margin-right: 4px;
}

.footer_contact_component > .email {
  font-size: 1.5vh;
  color: white;
  font-weight: 500;
  font-family: var(--primary-font-family);
  cursor: pointer;
}

.footer_icon {
  width: 10%;
  height: 2vh;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: rgb(235, 235, 235);
  font-size: 1vh;
  font-weight: 800;
  font-family: var(--primary-font-family);
}

.footer_icon > img {
  height: 2vh;
  margin-left: 15px;
  cursor: pointer;
}

.copyright {
  width: 50%;
  height: 2vh;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: rgb(235, 235, 235);
  font-size: 1.5vh;
  font-weight: 500;
  font-family: var(--primary-font-family);
  cursor: pointer;
}

@media only screen and (max-device-width: 768px) {
  .down
  {
    width: auto;
    flex-direction: column;
    padding: 10px;
  }

  /* footer {
    height: 5vh;
    position: fixed;
    bottom: 0;
    left: 0;
  } */

  .up {
    /* display: none; */
    height: auto;
  }

  .footer_up_contaoner_wrap
  {
    flex-direction: column;
  }

  .footer_content_text
  {
    margin-bottom: 5px;
  }

  .footer_inner_container_heading
  {
    width: auto;
    margin-bottom: 10px;
  }

  .footer_inner_container_content
  {
    text-align: center;
  }

  .footer_inner_container
  {
    width: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .footer_contact_component
  {
    flex-direction: column;
  }

  .footer_icon {
    width: auto;
  }
  .copyright {
    width: auto;
  }
}
